import React, { useEffect } from "react";
import Banner from '../../../components/Banner/Banner'
import Botao from "../../../components/Botao/Botao";
import Footer from "../../../components/Footer/Footer";
import SectionCertificado from "../../../components/Section/SectionCertificado";
import { NavLink } from "react-router-dom";
import '../style.css'
import check from '../../../images/ERP/check (3).svg'

function CertificadoSaibaMais() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="banner-padding-top">
                <section className="certificado-banner-cpf">
                    <div className="container-banner-certificado">
                        <div className="content-banner" >
                            <h1 style={{fontSize: "55px"}}>CERTIFICADO DIGITAL e-CPF</h1>
                            <h2>Sem burocracia 100% Online</h2>
                            <h3>
                                com uma Empresa Brasileira de 54 anos referência em Tecnologia
                            </h3>
                        </div>
                    </div>
                </section>
            </div>
            <section className="razoes">
                <h2 style={{ textAlign: 'start', margin: '0 0 30px 0' }}>O que é um e-CPF?</h2>
                <p>O Certificado Digital e-CPF é a versão eletrônica do CPF (Cadastro de Pessoa Física), oferecendo uma forma segura e moderna de autenticação digital. Com ele, você garante a validade jurídica das suas transações e documentos eletrônicos.</p>
            </section>
            <section className="planos">
                <h2 style={{ margin: '0 0 30px 0' }}>Vantagens do Certificado Digital e-CPF:</h2>
                <div className="certificado-itens">
                    <ul>
                        <li><img src={check} alt='Ícone de check' /><b>Assinatura Digital com Validade Jurídica:</b></li>
                        <li>Assine contratos, declarações e documentos eletrônicos com validade jurídica, garantindo autenticidade e integridade.</li>
                        <li><img src={check} alt='Ícone de check' /><b>Acesso a Serviços Públicos:</b></li>
                        <li>Simplifique o acesso a serviços públicos online, como Receita Federal, previdência social e outros órgãos, com total segurança.</li>
                        <li><img src={check} alt='Ícone de check' /><b>Declaração do Imposto de Renda:</b></li>
                        <li>Agilize o processo de declaração do imposto de renda, assinando digitalmente seus documentos fiscais.</li>
                        <li><img src={check} alt='Ícone de check' /><b>Segurança em Operações Bancárias:</b></li>
                        <li>Realize operações bancárias online com maior segurança, utilizando o e-CPF para autenticação e assinatura digital.</li>
                        <li><img src={check} alt='Ícone de check' /><b>Proteção da Identidade Digital:</b></li>
                        <li>Reforce a segurança da sua identidade digital, impedindo fraudes e garantindo que apenas você tenha acesso às suas informações.</li>
                        <li><img src={check} alt='Ícone de check' /><b>Acesso a Plataformas Digitais:</b></li>
                        <li>Acesse plataformas digitais, como sistemas corporativos e serviços online, de forma segura e conveniente.</li>
                        <li><img src={check} alt='Ícone de check' /><b>Compra e Venda Online:</b></li>
                        <li>Assine digitalmente contratos de compra e venda online, proporcionando segurança tanto para comprador quanto para vendedor.</li>
                    </ul>
                </div>
            </section>
            <SectionCertificado />
            <section className="planos">
                <h2 style={{ textAlign: 'center', fontSize: '24px' }}>Adquirir meu Certificado Digital e-CPF:</h2>
                <div className="elemento-centralizado">
                    <NavLink to="/certificado">
                        <Botao texto={'Adquira aqui seu Certificado Digital'} />
                    </NavLink>
                </div>
            </section>
            <Footer footerValid={true} />
        </>
    )
}

export default CertificadoSaibaMais