import React, { Component } from "react";
import CardCertificado from "./Card/CardCertificado";
import token from "../images/Certificado/token.svg";
import file from "../images/Certificado/file.svg";
import cloud from "../images/Certificado/cloud.svg";
import cartaoleitora from "../images/Certificado/cartaoleitora.svg";
import cartao from "../images/Certificado/cartao.svg";
import certificadoescudo from "../images/Certificado/certificadoescudo.svg";

class ObjectList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTypes: [],
    };
  }

  handleCheckboxChange = (type) => {
    const { selectedTypes } = this.state;

    if (selectedTypes.includes(type)) {
      this.setState({
        selectedTypes: selectedTypes.filter((t) => t !== type),
      });
    } else {
      this.setState({
        selectedTypes: [...selectedTypes, type],
      });
    }
  };

  verificaIcone = (item) => {
    if (item.resumo.includes("Cartão + Leitora")) {
      return cartaoleitora;
    } else if (item.resumo.includes("Cartão")) {
      return cartao;
    } else if (item.resumo.includes("Token")) {
      return token;
    } else if (item.resumo.includes("Arquivo")) {
      return file;
    } else if (item.resumo.includes("Nuvem")) {
      return certificadoescudo;
    } else {
      return certificadoescudo;
    }
  };

  verificaDescricao = (item) => {
    if (item.includes("Certificado Digital e-CNPJ")) {
      return (
        <p>
          Escolha o formato de mídia e a validade do seu certificado para
          completar sua compra. <br /> Os certificados Digitais e-CNPJ tem
          validade de 1, 2 e 3 anos.
        </p>
      );
    } else if (item.includes("Certificado Digital e-CPF")) {
      return (
        <p>
          Escolha o formato de mídia e a validade do seu certificado para
          completar sua compra. <br /> Os certificados Digitais e-CPF tem
          validade de 1, 2 e 3 anos.
        </p>
      );
    } else if (item.includes("Certificado Digital Conectividade Social ICP")) {
      return (
        <p>
          Escolha o formato de mídia para completar sua compra. <br /> Os
          certificados Digitais Social ICP tem validade somente de 1,5 ano.
        </p>
      );
    } else if (item.includes("Certificado Digital NF-e")) {
      return (
        <p>
          Escolha o formato de mídia e a validade do seu certificado para
          completar sua compra. <br /> Os certificados Digitais NF-e tem
          validade de 1 e 3 anos.
        </p>
      );
    } else if (item.includes("Certificado Digital CT-e")) {
      return (
        <p>
          Escolha o formato de mídia e a validade do seu certificado para
          completar sua compra. <br /> Os certificados Digitais CT-e A1 tem
          validade somente de 1 ano. Os Certificados A3 tem validade somente de
          3 anos.
        </p>
      );
    }
  };

  render() {
    const { selectedTypes } = this.state;

    const { Produto } = this.props;

    const tiposUnicos = [...new Set(Produto.map((produto) => produto.tipo))];

    return (
      <div className="container-certificado-digital">
        <div className="container-busca-certificado">
          <div className="content-busca-certificado">
            {tiposUnicos.map((tipo) => (
              <label key={tipo}>
                <input
                  type="checkbox"
                  value={tipo}
                  onChange={() => this.handleCheckboxChange(tipo)}
                  checked={selectedTypes.includes(tipo)}
                />
                {tipo}
              </label>
            ))}
          </div>
        </div>
        <div className="container-certificado-home">
          {tiposUnicos.map((tipo) => {
            const produtosDoTipo = Produto.filter(
              (produto) => produto.tipo === tipo
            );
            if (
              produtosDoTipo.length > 0 &&
              (selectedTypes.length === 0 || selectedTypes.includes(tipo))
            ) {
              return (
                <div key={tipo}>
                  {/* <h2>{tipo}</h2> */}
                  {/* {this.verificaDescricao(tipo)} */}
                  <div className="container-cards-certificado">
                    {produtosDoTipo.map((item) => {
                      return (
                        <CardCertificado
                          key={item.id}
                          id={item.id}
                          icone={this.verificaIcone(item)}
                          titulo={item.descricaoResumida}
                          //texto={item.resumo}
                          validade={item.anos}
                          produto={item.tipo}
                        />
                      );
                    })}
                  </div>
                </div>
              );
            }
            return null;
          })}
        </div>
      </div>
    );
  }
}

export default ObjectList;
