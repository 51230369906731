import React, { useContext, useEffect, useState } from "react";
import Botao from "../../../components/Botao/Botao";
import Footer from "../../../components/Footer/Footer";
import { NavLink } from "react-router-dom";
import "../style.css";
import certificadoescudo from "../../../images/Certificado/certificadoescudo.svg";
import ctacert from "../../../images/Certificado/ctacert.png";
import { CompraContext } from "../../../context/CompraContext";
import { EmailContext } from "../../../context/EmailContext";
import Loading from "../../../components/Loading/Loading";
import ObjectList from "../../../components/ObjectList";

function CertificadoHome() {
  const { ObterProdutos, Produto, setLoading, loading, setCadastro, Revenda } =
    useContext(CompraContext);
  const { setContabilidade, setPdf } = useContext(EmailContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    setPdf("");
    setCadastro("");
    ObterProdutos()
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }, []);

  const contato = () => {
    setContabilidade("Certificado Digital");
  };

  return (
    <>
      {loading && <Loading />}
      <div className="banner-padding-top">
        <section className="certificado-banner">
          <div className="container-banner-certificado">
            <div className="content-banner">
              <h2>Compre aqui seu</h2>
              <h1>CERTIFICADO DIGITAL!</h1>
              <h2>Sem burocracia 100% Online</h2>
              <h3>
                com uma Empresa Brasileira de 54 anos referência em Tecnologia
              </h3>
            </div>
          </div>
        </section>
      </div>
      <section className="certificado">
        {Produto && <ObjectList Produto={Produto} Revenda={Revenda} />}
      </section>
      <Footer footerValid={true} certificado={true} />
    </>
  );
}

export default CertificadoHome;
