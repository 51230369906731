import { useState } from "react";
import axios from "axios";

const useAutoCep = () => {
  const [disabledFields, setDisabledFields] = useState({
    logradouro: false,
    bairro: false,
    cidade: false,
    uf: false,
  });
  const [loadingCep, setLoadingCep] = useState(false);
  const [isCepInvalido, setIsCepInvalido] = useState(false);

  const fetchEndereco = async (cep) => {
    try {
      setLoadingCep(true);
      setIsCepInvalido(false);

      const cepSemTraco = cep.replace("-", "");

      const response = await axios.get(
        `https://viacep.com.br/ws/${cepSemTraco}/json/`
      );

      let logradouroResponse;
      let bairroResponse;
      let cidadeResponse;
      let ufResponse;
      let endereco = null;

      setIsCepInvalido(response.data.erro);
      if (!response.data.erro) {
        logradouroResponse = response.data.logradouro || "";
        bairroResponse = response.data.bairro || "";
        cidadeResponse = response.data.localidade || "";
        ufResponse = response.data.uf || "";

        setDisabledFields({
          logradouro: Boolean(response.data.logradouro),
          bairro: Boolean(response.data.bairro),
          cidade: Boolean(response.data.localidade),
          uf: Boolean(response.data.uf),
        });
        endereco = {
          logradouro: logradouroResponse,
          bairro: bairroResponse,
          cidade: cidadeResponse,
          uf: ufResponse,
        };
      }
      return endereco;
    } catch (error) {
      console.error("Erro ao buscar o endereço:", error);
    } finally {
      setLoadingCep(false);
    }
  };

  return { fetchEndereco, disabledFields, loadingCep, isCepInvalido };
};
export default useAutoCep;
