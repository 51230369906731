import React, { useContext, useState, useEffect } from "react";
import './style.css'
import { CompraContext } from "../../context/CompraContext";
import { IoMdClose } from 'react-icons/io'
import { MdContentCopy } from 'react-icons/md'
import logo from '../../images/Header/logoheader.svg'
import { useNavigate } from "react-router-dom";
import Erro from "../Alert/Erro";
import Loading from "../Loading/Loading";

function ModalPix({ close }) {
    const { pixCola, QRCode, transactionId, DadosPix, ProdutoCompra, ConsultaPix, setMsgErro, msgErro, Cupom, setCupom, setAbreModal, setDescricao, Desconto, Revenda, setRevenda, loading, VendaKey } = useContext(CompraContext)
    const [copySuccess, setCopySuccess] = useState('');
    const imageUrl = `data:image/png;base64, ${QRCode}`
    const [seconds, setSeconds] = useState(600);
    const [botaoAtivo, setBotaoAtivo] = useState(true);

    const navigate = useNavigate()

    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(pixCola);
            setCopySuccess('Texto copiado para a área de transferência')
            setTimeout(() => {
                setCopySuccess("");
            }, 6000);
        } catch (error) {
            console.log('Falha ao copiar o texto', error);
        }
    };

    function verificaPix(item) {
        if (!item) {
            return <p>Aguardando pagamento...</p>
        } else if (item === 'CREATED') {
            return <p>Transação ainda não aprovada...</p>;
        } else {
            return <p>Erro ao realizar pagamento!</p>
        }
    }

    let count = 0;
    const consulta = () => {
        setBotaoAtivo(false);
        ConsultaPix(transactionId, VendaKey)
            .then(async (response) => {
                if (response.data === 'CREATED') {
                    if (count < 2) {
                        setTimeout(() => {
                            consulta()
                            count++;
                        }, 30000);
                    } else {
                        setMsgErro("Tempo expirado")
                        setCupom('')
                        setAbreModal(false)
                        setTimeout(() => {
                            setMsgErro("");
                        }, 6000);
                        count = 0
                        return
                    }
                } else if (response.data === 'APPROVED') {
                    count = 0
                    setCupom('')
                    setDescricao('')
                    setRevenda('')
                    return navigate("/compra-finalizada")
                } else {
                    count = 0
                    setMsgErro("Erro ao realizar pagamento!")
                    setCupom('')
                    setTimeout(() => {
                        setMsgErro("");
                    }, 6000);
                    return
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            setSeconds(prevSeconds => {
                if (prevSeconds === 0) {
                    clearInterval(intervalId);
                    return 0;
                }
                return prevSeconds - 1;
            });
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const formatTime = time => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    };


    return (
        <>
            {msgErro &&
                <Erro />
            }
            {loading &&
                <Loading />
            }
            <div className="modal">
                <div className="modal-interno">
                    <div style={{ display: 'flex', alignSelf: 'flex-end' }}>
                        <button className="btn-close-modal" onClick={() => close(false)}><IoMdClose className="icon-close" /></button>
                    </div>
                    <div className="elemento-centralizado" >
                        <img src={logo} alt="Logo Exactus" className="logo-pix" />
                    </div>
                    <p style={{ textAlign: 'center', fontWeight: 'bold', margin: '20px 0 0 0', fontSize: '20px' }}>Pagar PIX no valor de R$
                        {Cupom ?
                            <>{(ProdutoCompra.anos[0].valor.toFixed(2) - ((ProdutoCompra.anos[0].valor.toFixed(2) * Desconto) / 100)).toFixed(2)} </>
                            :
                            <>{ProdutoCompra.anos[0].valor.toFixed(2)} </>}
                        válido por <span style={{color: 'red'}}>{formatTime(seconds)}</span>
                    </p>
                    <p style={{ textAlign: 'center' }} >Abra o app do seu banco e escaneie a imagem ou copie e cole o código QR Code</p>
                    <div className="elemento-centralizado">
                        <img src={imageUrl} alt="QR Code" className="pix" />
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: '10px' }}>
                        <button className="botao botao-pix" onClick={() => copyToClipboard()}>Copiar código QR Code <MdContentCopy style={{ marginLeft: '5px' }} /></button>
                        <p className="copy">{copySuccess}</p>
                        <button className="botao-branco botao-pix" style={{ height: "60px" }} onClick={() => botaoAtivo && consulta()} disabled={!botaoAtivo}>Confirmar pagamento</button>
                        {verificaPix(DadosPix)}
                    </div>
                </div>
            </div>
        </>
    )
}

export default ModalPix