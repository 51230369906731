import email from './sendmail.svg'
import calendario from './schedule.svg'
import like from './like.svg'
import shopping from './shopping.svg'

function SectionCertificado() {
    return (
        <section className="razoes">
            <h2>Passo a passo para a emissão do Certificado</h2>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                    <div className="elemento-centralizado">
                        <img src={shopping} alt='Ícone de compra' style={{ width: '50px', height: '50px' }} />
                    </div>
                    <h3 style={{ fontSize: '18px', margin: '15px 0', textAlign: 'center' }}>Compre seu certificado</h3>
                    <p style={{ margin: '0', textAlign: 'center' }}>Escolha seu certificado de acordo com sua necessidade. Descubra a seguir qual escolher.</p>
                </div>
                <div>
                    <div className="elemento-centralizado">
                        <img src={calendario} alt='Ícone de calendario' style={{ width: '45px', height: '50px' }} />
                    </div>
                    <h3 style={{ fontSize: '18px', margin: '15px 0', textAlign: 'center' }}>Agendamento</h3>
                    <p style={{ margin: '0', textAlign: 'center' }}>Agora que você comprou, basta agendar com nossa equipe para provar que você é você.</p>
                </div>
                <div>
                    <div className="elemento-centralizado">
                        <img src={email} alt='Ícone de e-mail' style={{ width: '65px', height: '50px' }} />
                    </div>
                    <h3 style={{ fontSize: '18px', margin: '15px 0', textAlign: 'center' }}>Envio de documentos</h3>
                    <p style={{ margin: '0', textAlign: 'center' }}>Envie por whatsapp os documentos necessários para validação: RG, CPF, etc.</p>
                </div>
                <div>
                    <div className="elemento-centralizado">
                        <img src={like} alt='Ícone de like' style={{ width: '50px', height: '50px' }} />
                    </div>
                    <h3 style={{ fontSize: '18px', margin: '15px 0', textAlign: 'center' }}>Emissão do cerificado</h3>
                    <p style={{ margin: '0', textAlign: 'center' }}>Nossa equipe verificará sua identidade e emitirá seu certificado digital.</p>
                </div>
            </div>
        </section>
    )
}

export default SectionCertificado