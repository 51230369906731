import React, { useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import "./style.css";
import { CompraContext } from "../../context/CompraContext";

function CardCertificado({ titulo, icone, texto, validade, id, produto }) {
  const { ConsultaProdutos } = useContext(CompraContext);

  const [idValidade, setIdValidade] = useState(validade[0].id);
  const [precoValidade, setPrecoValidade] = useState(validade[0].valor);
  const black = "-black";

  function handleChange(id) {
    const posicaoValidade = validade.findIndex((item) => item.id == id)
    setPrecoValidade(validade[posicaoValidade].valor)
    setIdValidade(validade[posicaoValidade].id)
  }

  function verificaProduto(produto) {
    if (produto.includes("Certificado Digital e-CNPJ")) {
      return "/certificado-saiba-mais/cnpj";
    } else if (produto.includes("Certificado Digital e-CPF")) {
      return "/certificado-saiba-mais/cpf";
    }
  }

  function verificaIcone(icone) {
    if (icone.includes("file")) {
      return "120px";
    } else {
      return "100px";
    }
  }

  function verificaTheme(icone) {
    if (icone.includes("file")) {
      return black;
    } else {
      return "";
    }
  }

  function verificaTexto(icone) {
    if (icone.includes("file")) {
      return "#F5F5F5";
    } else {
      return "#1F1F1F";
    }
  }

  return (
    <div className={`card-certificado-digital${verificaTheme(icone)}`}>
      <div className={`elemento-centralizado${verificaTheme(icone)}`}>
        <img
          src={icone}
          alt="Ícone"
          className="icon-certificado"
          style={{
            width: verificaIcone(icone),
            maxWidth: verificaIcone(icone),
            height: verificaIcone(icone),
          }}
        />
      </div>
      <h1>{titulo}</h1>
      <div className={`content-card-certificado${verificaTheme(icone)}`}>
        <h2 style={{ color: verificaTexto(icone) }}>{texto}</h2>
        <div>
          <label
            htmlFor="validade"
            style={{
              fontSize: "15px",
              fontWeight: "bold",
              color: verificaTexto(icone),
            }}
          >
            Validade:{" "}
          </label>
          <select onChange={(e) => handleChange(e.target.value)}>
            {validade.map((item) => (
              <option key={item.id} value={item.id}>
                {item.descricao}
              </option>
            ))}
          </select>
        </div>
        <h3>R$ {precoValidade.toFixed(2)}</h3>
        <div className={`elemento-centralizado${verificaTheme(icone)}`}>
          <NavLink to="/cadastro">
            <button onClick={() => ConsultaProdutos(id, idValidade)}>
              Comprar
            </button>
          </NavLink>
          <NavLink to={verificaProduto(produto)}>
            <div style={{ marginLeft: "20px" }}>
              <button className={`botao-saiba-mais${verificaTheme(icone)}`}>
                Saiba Mais
              </button>
            </div>
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default CardCertificado;
