import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./fonts/BigNoodleTitling/big_noodle_titling.ttf";
import { CompraContextProvider } from "./context/CompraContext";
import { EmailContextProvider } from "./context/EmailContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <EmailContextProvider>
      <CompraContextProvider>
        <App />
      </CompraContextProvider>
    </EmailContextProvider>
  </React.StrictMode>
);

reportWebVitals();
