export const formatarTelefone = (telefone) => {
  const apenasNumeros = telefone.replace(/\D/g, "");
  const telefoneLimite = apenasNumeros.slice(0, 11);
  const telefoneFormatado = telefoneLimite
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{5})(\d)/, "$1-$2");
  return telefoneFormatado;
};

export const formatarCNPJ = (cnpj) => {
  const apenasNumeros = cnpj.replace(/\D/g, "");
  const cnpjLimite = apenasNumeros.slice(0, 14);
  const cnpjFormatado = cnpjLimite
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d{1,2})$/, "$1-$2");

  return cnpjFormatado;
};

export const convertToISO = (dateString) => {
  const [day, month, year] = dateString.split("/");
  const date = new Date(`${year}-${month}-${day}T00:00:00Z`);
  return date.toISOString();
};

export const limpaTelefone = (telefone) => {
  return telefone.replace(/[()\s\-]/g, "");
};

export const limpaCep = (cep) => {
  return cep.replace(/-/g, "");
};
