import "./style.css";

import InputMask from "react-input-mask";
import { useContext, useEffect, useRef } from "react";
import { EmailContext } from "../../context/EmailContext";
// componentes
import Loading from "../../components/Loading/Loading";
import Erro from "../../components/Alert/Erro";
import CardSimplifique from "../../components/Card/CardSimplifique";
import CardMaximizar from "../../components/Card/CardMaximizar";
import CardPreco from "../../components/Card/CardPreco";
import Sucesso from "../../components/Alert/Sucesso";
import Footer from "../../components/Footer/Footer";
// imagens
import background_azul from "../../images/Busca/bola-azul.svg";
import ebuscar from "../../images/Busca/logo-ebuscar.svg";
import carteira from "../../images/Busca/carteira.svg";
import nota from "../../images/Busca/nota.svg";
import planilha from "../../images/Busca/planilha.svg";
import casa from "../../images/Busca/casa.svg";
import documento_nuvem from "../../images/Busca/documento-nuvem.svg";
import icon_01 from "../../images/Busca/01.svg";
import icon_02 from "../../images/Busca/02.svg";
import icon_03 from "../../images/Busca/03.svg";
import icon_04 from "../../images/Busca/04.svg";

function Buscar() {
  useEffect(() => {
    window.scrollTo(0, 0);
    setPdf("");
  }, []);

  const contato = useRef(null);

  const scrollToContato = () => {
    contato.current.scrollIntoView({ behavior: "smooth" });
  };

  const {
    loading,
    ColetarLead,
    msgSucessoEmail,
    msgErroEmail,
    setMsgErroEmail,
    nomeContato,
    setNomeContato,
    emailContato,
    setEmailContato,
    telefone,
    setTelefone,
    setContabilidade,
    contabilidade,
    setPdf,
  } = useContext(EmailContext);

  useEffect(() => {
    setContabilidade("e-Busca");
  }, [contabilidade]);

  function verifica(e) {
    e.preventDefault();

    if (nomeContato === "" || emailContato === "" || telefone === "") {
      setMsgErroEmail("Preencha todos os campos!");
      setTimeout(() => {
        setMsgErroEmail("");
      }, 6000);
      return;
    }

    ColetarLead();
  }
  return (
    <main>
      {msgSucessoEmail && <Sucesso />}
      {msgErroEmail && <Erro />}
      {loading && <Loading />}
      <section className="busca">
        <div className="conteudo-busca">
          <h1>
            Busque e <span>Gerencie</span> <br /> Documentos
            <span> Fiscais</span> <br /> com Facilidade
          </h1>

          <p>
            Tenha uma Gestão Fiscal eficiente com o e-Busca! Este poderoso
            <br />
            buscador simplifica a captura e o gerenciamento de documentos <br />
            fiscais eletrônicos. Fale agora mesmo com nossos especialistas e
            <br />
            comece a usar!
          </p>
          <div>
            <button onClick={scrollToContato} className="botao-azul">
              Fale Conosco
            </button>
          </div>
        </div>

        <div className="imagens-busca">
          <img
            className="background-azul"
            src={background_azul}
            alt="background"
          />
          <img className="logo-ebuscar" src={ebuscar} alt="logo-ebuscar" />
        </div>
      </section>

      <section className="simplifique">
        <div className="head-simplifique">
          <h1>
            Simplifique com o <span>e-Busca!</span>
          </h1>
          <p>
            Seu Cliente fica sabendo em tempo real quem emitiu NF-e para o CNPJ
            dele, seu <br /> Escritório recebe automaticamente todos os arquivos
            digitais XML do seu cliente.
          </p>
        </div>

        <div className="cards-simplifique">
          <CardSimplifique
            imagem={carteira}
            titulo={"Eficiência Operacional"}
            conteudo={
              "Potencialize a eficiência operacional com a automação de tarefas usando o e-Busca. Acelere seus processos mantendo a precisão indispensável."
            }
          />
          <CardSimplifique
            imagem={planilha}
            titulo={"Integridade dos Dados"}
            conteudo={
              "Assegure a confiabilidade e a atualização contínua das informações com o e-Busca, prevenindo inconsistências nos registros fiscais."
            }
          />
          <CardSimplifique
            imagem={nota}
            titulo={"Consultoria Fiscal"}
            conteudo={
              "Enriqueça suas relações comerciais proporcionando uma consultoria fiscal abrangente e estratégica, elevando o valor agregado aos seus serviços."
            }
          />
          <CardSimplifique
            imagem={casa}
            titulo={"Colaboração e Integração"}
            conteudo={
              "Facilite a troca de informações e a sincronização de dados, proporcionando acesso rápido e preciso às informações fiscais essenciais para quem depende de você."
            }
          />
        </div>
      </section>

      <section className="documentos">
        <div className="head-documentos">
          <div className="documentos-coleta">
            <span>
              Quais Documentos <br /> o e-Busca coleta?
            </span>
            <p>NF-e</p>
            <p>CT-e</p>
            <p>NFS-e</p>
            <p>CF-e</p>
            <p>NFC-e</p>
          </div>

          <img className="imagem-nuvem" src={documento_nuvem} alt="" />
        </div>

        <div className="maximizando">
          <h2>
            Maximizando a Eficiência Fiscal com o <span>e-Busca.</span>
          </h2>
          <p>
            Descubra como o e-Busca pode transformar a gestão fiscal do seu
            negócio com automação avançada e insights estratégicos.
          </p>

          <div className="cards-maximizar">
            <CardMaximizar
              imagem={icon_01}
              titulo={"Gestão Fiscal Abrangente"}
              conteudo={
                "Gerencie as notas fiscais de seus clientes em todos os aspectos fiscais com precisão e eficiência."
              }
            />
            <CardMaximizar
              imagem={icon_02}
              titulo={"Centralização e Automação"}
              conteudo={
                "Centralize a gestão de documentos em um único local com nossa solução que automatiza sua rotina fiscal."
              }
            />
            <CardMaximizar
              imagem={icon_03}
              titulo={"Relatórios Estratégicos"}
              conteudo={
                "Transforme seu serviço em uma consultoria estratégica, utilizando sues relatórios para uma tomada de decisão inteligente."
              }
            />
            <CardMaximizar
              imagem={icon_04}
              titulo={"Notificações Proativas"}
              conteudo={
                "Melhore o acompanhamento e a gestão fiscal com alertas em tempo real sempre que uma NF-e for emitida"
              }
            />
          </div>
          <div>
            <button onClick={scrollToContato} className="botao-azul">Fale com o consultor</button>
          </div>
        </div>
      </section>

      <section className="planos">
        <div className="head-planos">
          <h2>Planos para sua Empresa Contábil.</h2>
          <p>Escolha o que melhor se adpta à sua Empresa.</p>
        </div>
        <div className="cards-planos">
          <CardPreco
            nome="Plano Start"
            quantidade="Até 3.000 XML"
            preco="R$179,00"
            produto={"start"}
            onClick={scrollToContato}
            recursos={
              <ul className="recursos">
                <li>Monitora CNPJ, download e armazenamento de notas</li>
                <li>
                  Busca de notas de Saída através do Monitor instalado no ERP
                  dos clientes
                </li>
                <li>Busca de notas através da chave de acesso</li>
                <li>Download automático do XML</li>
                <li>Manifestação de Notas de Entrada</li>
              </ul>
            }
          />
          <CardPreco
            nome="Plano Basic"
            quantidade="Até 3.000 XML"
            preco="R$239,00"
            produto={"basic"}
            onClick={scrollToContato}
            recursos={
              <ul className="recursos">
                <li>Monitora CNPJ, download e armazenamento de notas</li>
                <li>
                  Busca de notas de Saída através do Monitor instalado no ERP
                  dos clientes
                </li>
                <li>Busca de notas através da chave de acesso</li>
                <li>Download automático do XML</li>
                <li>Manifestação de Notas de Entrada</li>
                <li style={{ fontWeight: "bolder" }}>Automação Estadual</li>
                <li>* Consulte condições para automações</li>
              </ul>
            }
          />
          <CardPreco
            nome="Plano Pro"
            quantidade="Até 5.000 XML"
            preco="R$359,00"
            produto={"pro"}
            onClick={scrollToContato}
            recursos={
              <ul className="recursos">
                <li>Monitora CNPJ, download e armazenamento de notas</li>
                <li>
                  Busca de notas de Saída através do Monitor instalado no ERP
                  dos clientes
                </li>
                <li>Busca de notas através da chave de acesso</li>
                <li>Download automático do XML</li>
                <li>Manifestação de Notas de Entrada</li>
                <li style={{ fontWeight: "bolder" }}>Automação Estadual</li>
                <li style={{ fontWeight: "bolder" }}>Automação Municipal</li>
                <li>* Consulte condições para automações</li>
              </ul>
            }
          />
        </div>
      </section>

      <section className="customizado">
        <div>
          <h2>
            Precisa de um Plano Customizado para sua{" "}
            <span>Empresa Contábil?</span>
          </h2>
        </div>
        <div>
          <button onClick={scrollToContato} className="botao-azul">Fale com o consultor</button>
        </div>
      </section>

      <section className="contato" ref={contato}>
        <div className="chamado">
          <h2>
            Entre em <span>contato conosco!</span>
          </h2>
          <p>
            Caso você tenha alguma dúvida, sugestão ou queira saber mais sobre a
            e Busca, preencha o formulário e entraremos em contato o mais breve
            possível.
          </p>
        </div>
        <div className="form">
          <form onSubmit={verifica}>
            <div>
              <label>Nome Completo</label>
              <input
                value={nomeContato}
                onChange={(e) => setNomeContato(e.target.value)}
                type="text"
              />
            </div>
            <div>
              <label>Telefone</label>
              <InputMask
                className="input-mask"
                mask="(99)99999-9999"
                style={{ width: "67%" }}
                value={telefone}
                onChange={(e) => setTelefone(e.target.value.replace(/[^0-9]/g, ""))}
              />
            </div>
            <div>
              <label>E-mail</label>
              <input
                value={emailContato}
                onChange={(e) => setEmailContato(e.target.value)}
                type="email"
              />
            </div>
            <div className="formButton">
              <button type="submit" className="botao-azul">
                Enviar
              </button>
            </div>
          </form>
        </div>
      </section>

      <Footer></Footer>
    </main>
  );
}

export default Buscar;
