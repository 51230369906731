import React, { useEffect } from "react";
import Footer from "../../components/Footer/Footer";

function PoliticaGarantia() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <>
            <div className="politica">
                <h1>Política de Garantia</h1>
                <p><b>Política de Garantia:</b></p>
                <p>Visando a melhoria contínua e satisfação de nossos clientes, colocamos à disposição todas as informações necessárias em caso de necessidade de uso da Garantia, Desistência e Reembolso.
                    <br />
                    <br />
                    O Código de Defesa do Consumidor - Lei 8.078/90 foi criado visando estabelecer regras de relacionamento entre empresas e consumidores. A política de garantia da Gcert Certificado Digital foi planejada seguindo veementemente por este conjunto de normas e os profissionais de nossa empresa foram preparados para direcionar e solucionar as possíveis ocorrências com base no CDC e na Legislação comercial.</p>
                <p><b>Beneficiários</b></p>
                <p>Esta política aplica-se a Pessoa Física ou Pessoa Jurídica que tenha adquirido os serviços e/ou produtos fornecidos pela Gcert Certificado.</p>
                <p><b>Garantia Legal Gcert Certificado Digital</b></p>
                <p>Está dado por lei, um prazo de 90 (noventa) dias que se iniciam a partir do fornecimento do serviço ou produto, mediante nota fiscal.
                    <br />
                    <br />
                    A empresa Gcert Certificado Digital garante os produtos contra qualquer defeito de fabricação, desde que identificados dentro do prazo de garantia (90 dias), conforme determina o artigo 26 do Código de Defesa do Consumidor, contados a partir da data do fornecimento do produto Gcert Certificado Digital.
                    <br />
                    <br />
                    Caso seja constatado pelos técnicos da VALID defeito de fabricação e/ou vícios no produto, seja token, leitora ou cartão, será efetuada a troca e/ou substituição do produto, desde que esteja dentro do período de garantia.
                    <br />
                    <br />
                    NÃO SÃO COBERTOS por essa política os casos em que o defeito tenha sido causado por imperícia, imprudência, mau uso ou ainda, bloqueio de senhas do produto e/ou serviço adquirido na Gcert Certificado.
                    <br />
                    <br />
                    Conforme o artigo 26, inciso II do Código de Defesa do Consumidor (Lei 8.078/90), é direito do consumidor apresentar reclamação referente ao produto que apresente vício aparente e de fácil constatação, desde que devidamente registrada através de nossos canais de atendimento, no prazo de até 90 (noventa) dias, contados a partir do fornecimento do serviço ou produto.
                    <br />
                    <br />
                    Caso as senhas PIN ou PUK sejam esquecidas ou digitadas por mais de 03 (três) vezes consecutivas de forma incorreta, o cartão ou o token SERÁ BLOQUEADO DEFINITIVAMENTE, SEM POSSIBILIDADE DE RECUPERAÇÃO, tornando as mídias e o certificado digital inutilizáveis.
                    <br />
                    <br />
                    Uma vez que o certificado do tipo A1 estiver emitido e instalado no computador do titular e/ou responsável pelo uso, a Gcert Certificado não possuirá responsabilidade pelo uso e proteção deste. Caso o computador seja formatado, danificado, ou substituído, o certificado digital precisará ser novamente instalado por meio da cópia de segurança (backup), realizado pelo próprio consumidor em sua máquina, após a emissão e instalação do certificado digital. Na falta de um backup, o cliente deverá realizar a compra e agendamento da validação para a emissão de um novo certificado digital.
                    <br />
                    <br />
                    A Gcert Certificado NÃO MANTÉM CÓPIA OU MÉTODO DE RECUPERAÇÃO DOS CERTIFICADOS DIGITAIS, conforme determinação normativa (DOC-ICP-04) da ICP-Brasil (Infraestrutura de Chaves Públicas Brasileira), órgão regulador do processo de Certificação Digital no Brasil.</p>
                <p><b>Direito de Arrependimento</b></p>
                <p>O cliente poderá desistir da aquisição do Certificado Digital no prazo de até 7 (sete) dias, a contar da data da COMPRA, nos termos do artigo 49 do Código de Defesa do Consumidor, Lei 8.078/90.Para que seja possível o reembolso do cliente pela desistência do produto, o Certificado Digital que estiver emitido deverá ser revogado pelo cliente.
                    <br />
                    <br />
                    A revogação deve ser realizada pelo titular e/ou responsável legal pelo uso do Certificado Digital via site, telefone ou na localidade onde realizou a emissão. Clique aqui para saber como realizar a revogação.
                    <br />
                    <br />
                    Para obter mais informações para solicitação de reembolso, clique aqui.
                    <br />
                    <br />
                    Para entrar em contato conosco, ligue no 11-3004-3454 para capitais e regiões metropolitanas ou 0800 725 4565 para demais localidades.</p>
            </div>
            <Footer />
        </>
    )
}

export default PoliticaGarantia